import { OrganizationReference } from "models/OrganizationReference";
import { ReportStatus } from "models/ReportStatus";
import { ReportingPeriod } from "models/ReportingPeriod";

export class PlanReportHeaderData {
    constructor(
        public title: string,
        public id: number,
        public identifier: string,
        public status: ReportStatus,
        public organization: OrganizationReference,
        public reportingPeriod: ReportingPeriod
    ) { }

    get organizationDetails() {
        var organizationDetails = this.organization.details;

        if(!organizationDetails) {
            organizationDetails = "Not Provided";
        }

        return organizationDetails;
    }
}
