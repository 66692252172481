import { bindable, bindingMode, containerless, autoinject, PLATFORM } from "aurelia-framework";
import { EventAggregator } from "aurelia-event-aggregator";
import { DialogService, DialogSettings } from "aurelia-dialog";
import { saveAs } from "file-saver";
import * as toastr from "toastr";
import { StatePlanReportService } from "services/StatePlanReportService";
import { UnitPlanReportService } from "services/UnitPlanReportService";
import { ZonePlanReportService } from "services/ZonePlanReportService";
import { CentralPlanReportService } from "services/CentralPlanReportService";
import { OrganizationType } from "models/OrganizationType";
import { ExcelReportType } from "models/ExcelReportType";
import { PlanReportHeaderData } from "resources/plan-report-header/PlanReportHeaderData";
import { SignalrWrapper } from "signalrwrapper";
import { CentralReportUpdated } from "resources/plan-report-header/CentralReportUpdated";
import { StateReportUpdated } from "resources/plan-report-header/StateReportUpdated";
import { ZoneReportUpdated } from "resources/plan-report-header/ZoneReportUpdated";
import { UnitReportUpdated } from "resources/plan-report-header/UnitReportUpdated";
import { YesNo } from "resources/yes-no-dialog/YesNo";
import { AppRouter } from "aurelia-router";
import { ReportStatus } from "models/ReportStatus";
import { ReportingFrequency } from "models/ReportingFrequency";
import { AuthService } from "auth-service";

@containerless
@autoinject
export class PlanReportShell {
    isDownloadingList = false;
    isDownloadingDetail = false;
    isResettingPlan = false;
    isResettingYPlanFromQ = false;
    isResettingReport = false;
    isResettingYReportFromQ = false;
    isRecalculatingPlan = false;
    isRecalculatingYPlanFromQ = false;
    isRecalculatingReport = false;
    isRecalculatingYReportFromQ = false;
    signalreventhandlers: any = {};

    @bindable({ defaultBindingMode: bindingMode.oneWay }) headerData: PlanReportHeaderData;
    @bindable({ defaultBindingMode: bindingMode.oneWay }) organizationType: OrganizationType;
    @bindable({ defaultBindingMode: bindingMode.oneWay }) itemId: number;
    @bindable({ defaultBindingMode: bindingMode.oneWay }) reportStatus: ReportStatus;

    constructor(
        public appRouter: AppRouter,
        public auth: AuthService,

        public unitService: UnitPlanReportService,
        public stateService: StatePlanReportService,
        public zoneService: ZonePlanReportService,
        public centralService: CentralPlanReportService,
        public dialogService: DialogService,
        public signalr: SignalrWrapper,
        public ea: EventAggregator) {
            this.signalreventhandlers = {
                "UnitReportUpdated": this.onUnitReportUpdated,
                "UnitReportUpdateFailed": this.onUnitReportUpdateFailed,
                "ZoneReportUpdated": this.onZoneReportUpdated,
                "ZoneReportUpdateFailed": this.onZoneReportUpdateFailed,
                "StateReportUpdated": this.onStateReportUpdated,
                "StateReportUpdateFailed": this.onStateReportUpdateFailed,
                "CentralReportUpdated": this.onCentralReportUpdated,
                "CentralReportUpdateFailed": this.onCentralReportUpdateFailed
            };
        }

    async attached() {
        for (const key in this.signalreventhandlers) {
            if (this.signalreventhandlers.hasOwnProperty(key)) {
                this.signalr.on(key, this.signalreventhandlers[key]);
            }
        }
    }

    detached() {
        for (const key in this.signalreventhandlers) {
            if (this.signalreventhandlers.hasOwnProperty(key)) {
                this.signalr.off(key, this.signalreventhandlers[key]);
            }
        }
    }

    get copydownloadservice(): UnitPlanReportService | StatePlanReportService | ZonePlanReportService | CentralPlanReportService {
        switch(this.headerData.organization.organizationType) {
            case OrganizationType.Unit: return this.unitService;
            case OrganizationType.Zone: return this.zoneService;
            case OrganizationType.State: return this.stateService;
            case OrganizationType.Central: return this.centralService;
            default: throw new Error("Not Implemented");
        }
    }

    get resetrecalculateservice(): StatePlanReportService | ZonePlanReportService | CentralPlanReportService {
        switch(this.headerData.organization.organizationType) {
            case OrganizationType.Zone: return this.zoneService;
            case OrganizationType.State: return this.stateService;
            case OrganizationType.Central: return this.centralService;
            default: throw new Error("Not Implemented");
        }
    }

    async downloadAsList() {
        this.isDownloadingList = true;
        const blob = await this.copydownloadservice.downloadReport(this.headerData.id, ExcelReportType.List);
        this.isDownloadingList = false;
        if(!blob) {
            toastr.error("Error downloading")
            return;
        }
        saveAs(blob, `${this.headerData.id}.xlsx`);
    }

    async downloadAsDetail() {
        this.isDownloadingDetail = true;
        const blob = await this.copydownloadservice.downloadReport(this.headerData.id, ExcelReportType.Detail);
        this.isDownloadingDetail = false;
        if(!blob) {
            toastr.error("Error downloading")
            return;
        }
        saveAs(blob, `${this.headerData.id}.xlsx`);
    }

    async resetPlan() {
        const shouldReset = await this.dialogService.open({
            viewModel: PLATFORM.moduleName("resources/yes-no-dialog/yes-no-dialog"),
            model: new YesNo(
                "Are you sure you want to override only plan, excluding report, with `Generated Data`?",
                ""
            ),
        })
        .whenClosed(response => {
            if(response.wasCancelled) return false;
            return true;
        });

        if(!shouldReset) return;

        this.isResettingPlan = true;
        try {
            await this.resetrecalculateservice.resetPlan(this.headerData.organization.id, this.headerData.id);
        } catch(error) {
            this.isResettingPlan = false;
            console.log(error);
            toastr.error(error);
            return;
        }
        toastr.success("Overriding plan submitted");
    }

    async resetYPlanFromQ() {
        const shouldReset = await this.dialogService.open({
            viewModel: PLATFORM.moduleName("resources/yes-no-dialog/yes-no-dialog"),
            model: new YesNo(
                "Are you sure you want to override only plan, excluding report, with `Generated Data` and `Own Generated Data`?",
                ""
            ),
        })
        .whenClosed(response => {
            if(response.wasCancelled) return false;
            return true;
        });

        if(!shouldReset) return;

        this.isResettingYPlanFromQ = true;
        try {
            await this.resetrecalculateservice.resetYPlanFromQ(this.headerData.organization.id, this.headerData.id);
        } catch(error) {
            this.isResettingYPlanFromQ = false;
            console.log(error);
            toastr.error(error);
            return;
        }
        toastr.success("Overriding plan submitted");
    }

    async resetReport() {
        const shouldReset = await this.dialogService.open({
            viewModel: PLATFORM.moduleName("resources/yes-no-dialog/yes-no-dialog"),
            model: new YesNo(
                "Are you sure you want to override only report, excluding plan, with `Generated Data`?",
                ""
            ),
        })
        .whenClosed(response => {
            if(response.wasCancelled) return false;
            return true;
        });

        if(!shouldReset) return;

        this.isResettingReport = true;
        try {
            await this.resetrecalculateservice.resetReport(this.headerData.organization.id, this.headerData.id);
        } catch(error) {
            this.isResettingReport = false;
            console.log(error);
            toastr.error(error);
            return;
        }
        toastr.success("Overriding report submitted");
    }

    async resetYReportFromQ() {
        const shouldReset = await this.dialogService.open({
            viewModel: PLATFORM.moduleName("resources/yes-no-dialog/yes-no-dialog"),
            model: new YesNo(
                "Are you sure you want to override only report, excluding plan, with `Generated Data` and `Own Generated Data`?",
                ""
            ),
        })
        .whenClosed(response => {
            if(response.wasCancelled) return false;
            return true;
        });

        if(!shouldReset) return;

        this.isResettingYReportFromQ = true;
        try {
            await this.resetrecalculateservice.resetYReportFromQ(this.headerData.organization.id, this.headerData.id);
        } catch(error) {
            this.isResettingYReportFromQ = false;
            console.log(error);
            toastr.error(error);
            return;
        }
        toastr.success("Overriding report submitted");
    }

    async recalculatePlan() {
        const shouldRecalculate = await this.dialogService.open({
            viewModel: PLATFORM.moduleName("resources/yes-no-dialog/yes-no-dialog"),
            model: new YesNo(
                "Are you sure you want to recalculate `Generated Data` of only plan, excluding report, from child plans?",
                ""
            ),
        })
        .whenClosed(response => {
            if(response.wasCancelled) return false;
            return true;
        });
        if(!shouldRecalculate) return;

        this.isRecalculatingPlan = true;
        try {
            await this.resetrecalculateservice.recalculatePlan(this.headerData.organization.id, this.headerData.id);
        } catch(error) {
            this.isRecalculatingPlan = false;
            console.log(error);
            toastr.error(error);
            return;
        }
        toastr.success("Recalculation for plan submitted");
    }

    async recalculateYPlanFromQ() {
        const shouldRecalculate = await this.dialogService.open({
            viewModel: PLATFORM.moduleName("resources/yes-no-dialog/yes-no-dialog"),
            model: new YesNo(
                "Are you sure you want to recalculate `Generated Data` and `Own Generated Data` of only yearly plan, excluding report, from own quarter plans?",
                ""
            ),
        })
        .whenClosed(response => {
            if(response.wasCancelled) return false;
            return true;
        });
        if(!shouldRecalculate) return;

        this.isRecalculatingYPlanFromQ = true;
        try {
            await this.resetrecalculateservice.recalculateYPlanFromQ(this.headerData.organization.id, this.headerData.id);
        } catch(error) {
            this.isRecalculatingYPlanFromQ = false;
            console.log(error);
            toastr.error(error);
            return;
        }
        toastr.success("Recalculation for plan submitted");
    }

    async recalculateReport() {
        const shouldRecalculate = await this.dialogService.open({
            viewModel: PLATFORM.moduleName("resources/yes-no-dialog/yes-no-dialog"),
            model: new YesNo(
                "Are you sure you want to recalculate `Generated Data` of only report, excluding plan, from child reports?",
                ""
            ),
        })
        .whenClosed(response => {
            if(response.wasCancelled) return false;
            return true;
        });
        if(!shouldRecalculate) return;

        this.isRecalculatingReport = true;
        try {
            await this.resetrecalculateservice.recalculateReport(this.headerData.organization.id, this.headerData.id);
        } catch(error) {
            this.isRecalculatingReport = false;
            console.log(error);
            toastr.error(error);
            return;
        }
        toastr.success("Recalculation for report submitted");
    }

    async recalculateYReportFromQ() {
        const shouldRecalculate = await this.dialogService.open({
            viewModel: PLATFORM.moduleName("resources/yes-no-dialog/yes-no-dialog"),
            model: new YesNo(
                "Are you sure you want to recalculate `Generated Data` and `Own Generated Data` of only yearly report, excluding plan, from own quarter reports?",
                ""
            ),
        })
        .whenClosed(response => {
            if(response.wasCancelled) return false;
            return true;
        });
        if(!shouldRecalculate) return;

        this.isRecalculatingYReportFromQ = true;
        try {
            await this.resetrecalculateservice.recalculateYReportFromQ(this.headerData.organization.id, this.headerData.id);
        } catch(error) {
            this.isRecalculatingYReportFromQ = false;
            console.log(error);
            toastr.error(error);
            return;
        }
        toastr.success("Recalculation for report submitted");
    }

    isActive(routehref: string) {
        const route = this.appRouter.routes.find(r => r.href === routehref);
        const result= route
            ? route.navModel.isActive
            : false;
        return result;
    }

    get canDownload() {
        return (
            this.headerData &&
            this.headerData.id &&
            !this.isDownloadingList &&
            !this.isDownloadingDetail &&
            !this.isResettingPlan &&
            !this.isResettingYPlanFromQ &&
            !this.isResettingReport &&
            !this.isResettingYReportFromQ &&
            !this.isRecalculatingPlan &&
            !this.isRecalculatingReport &&
            !this.isRecalculatingYPlanFromQ &&
            !this.isRecalculatingYReportFromQ
        );
    }

    get canReset() {
        return (
            this.headerData &&
            this.headerData.id &&
            !this.isDownloadingList &&
            !this.isDownloadingDetail &&
            !this.isResettingPlan &&
            !this.isResettingYPlanFromQ &&
            !this.isResettingReport &&
            !this.isResettingYReportFromQ &&
            !this.isRecalculatingPlan &&
            !this.isRecalculatingReport &&
            !this.isRecalculatingYPlanFromQ &&
            !this.isRecalculatingYReportFromQ
        );
    }

    get canRecalculate() {
        return (
            this.headerData &&
            this.headerData.id &&
            !this.isDownloadingList &&
            !this.isDownloadingDetail &&
            !this.isResettingPlan &&
            !this.isResettingYPlanFromQ &&
            !this.isResettingReport &&
            !this.isResettingYReportFromQ &&
            !this.isRecalculatingPlan &&
            !this.isRecalculatingReport &&
            !this.isRecalculatingYPlanFromQ &&
            !this.isRecalculatingYReportFromQ
        );
    }

    get isReportingFrequencyYearly() {
        return this.headerData.reportingPeriod.reportingFrequency === ReportingFrequency.Yearly;
    }

    get isOrganizationTypeUnit() {
        return this.headerData.organization.organizationType === OrganizationType.Unit;
    }

    get isOrganizationTypeZone() {
        return this.headerData.organization.organizationType === OrganizationType.Zone;
    }

    get isOrganizationTypeState() {
        return this.headerData.organization.organizationType === OrganizationType.State;
    }

    get isOrganizationTypeCentral() {
        return this.headerData.organization.organizationType === OrganizationType.Central;
    }

    get isOrganizationTypeNotUnit() {
        return this.headerData.organization.organizationType !== OrganizationType.Unit;
    }

    get isReportStatusUnsubmitted() {
        return this.reportStatus !== ReportStatus.Submitted;
    }

    get canEditLastPeriod() {
        return this.auth.isSystemAdmin && this.reportStatus === ReportStatus.Submitted;
    }

    get canCopy() {
        return this.reportStatus === ReportStatus.Submitted;
    }

    clearResetRecalculateFlags() {
        this.isResettingPlan = false;
        this.isResettingYPlanFromQ = false;
        this.isResettingReport = false;
        this.isResettingYReportFromQ = false;
        this.isRecalculatingPlan = false;
        this.isRecalculatingYPlanFromQ = false;
        this.isRecalculatingReport = false;
        this.isRecalculatingYReportFromQ = false;
    }

    onUnitReportUpdated = async (id: number) => {
        this.clearResetRecalculateFlags();
        this.ea.publish(new UnitReportUpdated());
    }

    onUnitReportUpdateFailed = (e: {$values: string[]}) => {
        this.clearResetRecalculateFlags();
        toastr.error(e.$values.join("\n"));
    }

    onZoneReportUpdated = async (id: number) => {
        this.clearResetRecalculateFlags();
        this.ea.publish(new ZoneReportUpdated());
    }

    onZoneReportUpdateFailed = (e: {$values: string[]}) => {
        this.clearResetRecalculateFlags();
        toastr.error(e.$values.join("\n"));
    }

    onStateReportUpdated = async (id: number) => {
        this.clearResetRecalculateFlags();
        this.ea.publish(new StateReportUpdated());
    }

    onStateReportUpdateFailed = (e: {$values: string[]}) => {
        this.clearResetRecalculateFlags();
        toastr.error(e.$values.join("\n"));
    }

    onCentralReportUpdated = async (id: number) => {
        this.clearResetRecalculateFlags();
        this.ea.publish(new CentralReportUpdated());
    }

    onCentralReportUpdateFailed = (e: {$values: string[]}) => {
        this.clearResetRecalculateFlags();
        toastr.error(e.$values.join("\n"));
    }
}
