import { bindable, bindingMode, containerless, autoinject } from "aurelia-framework";
import { PlanReportHeaderData } from "resources/plan-report-header/PlanReportHeaderData";

@containerless
@autoinject
export class PlanReportHeader {
    @bindable({ defaultBindingMode: bindingMode.oneWay }) headerData: PlanReportHeaderData;

    constructor() {}
}
